import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Urban Fade
			</title>
			<meta name={"description"} content={"Where Tradition Meets Style"} />
			<meta property={"og:title"} content={"Urban Fade"} />
			<meta property={"og:description"} content={"Where Tradition Meets Style"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66a23551e778710018d2000a/images/652c5e2ab0919-mid-tile.webp?v=2024-07-25T11:30:21.178Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66a23551e778710018d2000a/images/652c5e2ab0919-mid-tile.webp?v=2024-07-25T11:30:21.178Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66a23551e778710018d2000a/images/652c5e2ab0919-mid-tile.webp?v=2024-07-25T11:30:21.178Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66a23551e778710018d2000a/images/652c5e2ab0919-mid-tile.webp?v=2024-07-25T11:30:21.178Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66a23551e778710018d2000a/images/652c5e2ab0919-mid-tile.webp?v=2024-07-25T11:30:21.178Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66a23551e778710018d2000a/images/652c5e2ab0919-mid-tile.webp?v=2024-07-25T11:30:21.178Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66a23551e778710018d2000a/images/652c5e2ab0919-mid-tile.webp?v=2024-07-25T11:30:21.178Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text">
				Urban Fade
			</Override>
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="text1" />
		</Components.Header>
		<Section padding="80px 0 80px 0" quarkly-title="CTA/Lead/Form-6">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 84px"
				margin="0px 0px 40px 0px"
				lg-grid-template-columns="1fr"
				sm-grid-gap="36px 24px"
			>
				<Box min-width="100px" min-height="100px" lg-padding="0px 150px 0px 0px" md-padding="0px 0 0px 0px">
					<Text margin="0px 0px 20px 0px" font="normal 700 42px/1.2 --fontFamily-sans">
						Join Us Today
					</Text>
					<Text margin="0px 0px 10px 0px" font="normal 300 22px/1.5 --fontFamily-sansHelvetica">
						Ready to experience unparalleled grooming services? Visit Urban Fade and let our expert barbers provide you with an exceptional grooming experience that goes beyond the ordinary. Because at Urban Fade, we’re crafting excellence, one cut at a time.
					</Text>
					<Text margin="1rem 0px 20px 0px" font="--headline3">
						Barrack Place, shop 2/268 Kent St, Sydney NSW 2000, Australia
						<br />
						<br />
						+61292901033
					</Text>
				</Box>
				<Image
					src="https://uploads.quarkly.io/66a23551e778710018d2000a/images/62bb1404a84a2f649b645d6c_1.jpg?v=2024-07-25T11:30:21.199Z"
					display="block"
					width="100%"
					height="100%"
					object-fit="cover"
					border-radius="25px"
					margin="0px 0px 0px 0"
					lg-order="-1"
					lg-height="500px"
					md-height="400px"
					srcSet="https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/62bb1404a84a2f649b645d6c_1.jpg?v=2024-07-25T11%3A30%3A21.199Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/62bb1404a84a2f649b645d6c_1.jpg?v=2024-07-25T11%3A30%3A21.199Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/62bb1404a84a2f649b645d6c_1.jpg?v=2024-07-25T11%3A30%3A21.199Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/62bb1404a84a2f649b645d6c_1.jpg?v=2024-07-25T11%3A30%3A21.199Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/62bb1404a84a2f649b645d6c_1.jpg?v=2024-07-25T11%3A30%3A21.199Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/62bb1404a84a2f649b645d6c_1.jpg?v=2024-07-25T11%3A30%3A21.199Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/62bb1404a84a2f649b645d6c_1.jpg?v=2024-07-25T11%3A30%3A21.199Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link3">
				Barrack Place, shop 2/268 Kent St, Sydney NSW 2000, Australia
				<br />
				+61292901033
			</Override>
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"661cef388e2e8e00217acf89"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});